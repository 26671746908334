import React from "react";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import { CircularProgress, Stack } from "@mui/material";
//
import { Services } from "api/Services";
import { OrderProps, PointProps, RouteProps, OrdersPagination } from "./types";
import { FiltersListItem } from "screens/DispatcherPlannerNew/Filters/OrderFilters";
import { convertTimezone } from "utils/convertTimezone";

const server = new Services();

interface PlannerProviderProps {
  children: React.ReactNode;
}

export const initialFilters = {
  dates: [null, null],
  dates2: [null, null],
  dates2Select: null,
  selectProjects: [],
  selectBranches: [],
  selectCities: [],
  selectCounties: [],
  selectDesiRange: "",
  selectDistributionDate: null,
  selectSlot: [],
  selectStatus: [],
  selectSenders: [],
  selectWarehouses: [],
};

export type Filters = {
  dates: any[];
  dates2: any[];
  dates2Select: FiltersListItem | null;
  selectProjects: FiltersListItem[];
  selectBranches: FiltersListItem[];
  selectCities: FiltersListItem[];
  selectCounties: FiltersListItem[];
  selectDesiRange: string;
  selectDistributionDate: Date | null;
  selectSlot: FiltersListItem[];
  selectStatus: FiltersListItem[];
  selectSenders: FiltersListItem[];
  selectWarehouses: FiltersListItem[];
};

export const initialRouteFilters = {
  dates: [null, null],
  selectProjects: [],
  selectBranches: [],
  selectStatus: [],
};

type RouteFilters = {
  dates: any[];
  selectProjects: FiltersListItem[];
  selectBranches: FiltersListItem[];
  selectStatus: FiltersListItem[];
};

type SelectRouteProps = {
  route: RouteProps;
  route_color: string;
  routeCoords: Array<{ latitude: number; longitude: number }>;
  orders: OrderProps[];
};

type UpdateNewRoute = {
  route: RouteProps;
  callback: VoidFunction;
};
type MapLegendsStateProps = {
  siparisler: boolean;
  rotalar: boolean;
  surucular: boolean;
  depolar: boolean;
  subeler: boolean;
};
const MapLegendsStateInitial: MapLegendsStateProps = {
  siparisler: true,
  rotalar: true,
  surucular: false,
  depolar: false,
  subeler: false,
};

type PlannerContextType = {
  refreshData(routeFilters: RouteFilters, filters: Filters): void;
  points: PointProps[];
  pointsSame: PointProps[];
  setPointSearchList: (points: PointProps[]) => void;
  orders: OrderProps[];
  ordersCount: number;
  ordersPagination: OrdersPagination;
  setOrdersPagination(props: OrdersPagination): void;
  // Filters
  visibleFilters: boolean;
  setVisibleFilters(visible: boolean): void;
  // Order Filters
  filters: Filters;
  ordersLoading: boolean;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  // Route Filters
  routes: RouteProps[];
  routesLoading: boolean;
  routeFilters: RouteFilters;
  visibleRouteFilters: boolean;
  setVisibleRouteFilters(visible: boolean): void;
  setRouteFilters(routeFilters: RouteFilters): void;
  getRoutes(routeFilters: RouteFilters): void;
  //
  getOrders(filters: Filters, paginations?: OrdersPagination): void;
  deleteRoute: (routeId: string) => void;
  // Select Route
  selectRoutes: SelectRouteProps[];
  handleSelectRoutes(ids: string[], callback: VoidFunction): void;
  handleSelectRoute(
    route: RouteProps,
    color: string,
    callback: VoidFunction
  ): void;
  deleteSelectRoute(route: RouteProps): void;
  updateNewRoute(route: UpdateNewRoute): void;
  addNewRoutes(routes: RouteProps[]): void;
  // Select Points
  selectPoints: PointProps[];
  handleSelectPointsLoading: boolean;
  handleSelectPoints(points: PointProps[]): void;
  deleteSelectPoints(): void;
  handleSelectPointsToggleTable(): void;
  selectPointsOrders: OrderProps[];
  selectPointsTableVisible: boolean;
  clickPoint: PointProps | null;
  handleClickPoint(point: PointProps): void;
  handleClickClosePoint(): void;
  // Map Legends
  mapLegends: MapLegendsStateProps;
  onChangeMapLegends: (mapLegends: MapLegendsStateProps) => void;
  mapLegendsWarehouses: Array<any>;
  mapLegendsBranches: Array<any>;
  // Select Orders
  selectOrders: OrderProps[];
  handleSelectOrders: (orders: OrderProps[]) => void;
  handleSelectOrder: (order: OrderProps) => void;
  deleteSelectOrder: (order: OrderProps) => void;
  //
  selectMapRouteOrders: OrderProps[];
  setSelectMapRouteOrders: (orders: OrderProps[]) => void;
  routeOrdersFilters: {
    completed: boolean;
    waiting: boolean;
  };
  setRouteOrdersFilters: React.Dispatch<
    React.SetStateAction<{
      completed: boolean;
      waiting: boolean;
    }>
  >;
};

const PlannerContext = React.createContext<PlannerContextType>({
  refreshData: (routeFilters, filters) => {},
  points: [],
  pointsSame: [],
  setPointSearchList: () => {},
  orders: [],
  ordersCount: 0,
  ordersPagination: {
    page: 0,
    pageSize: 0,
  },
  setOrdersPagination: () => {},
  // Filters
  ordersLoading: true,
  filters: {
    dates: [],
    dates2: [],
    dates2Select: null,
    selectProjects: [],
    selectBranches: [],
    selectDesiRange: "",
    selectDistributionDate: null,
    selectSlot: [],
    selectCities: [],
    selectCounties: [],
    selectStatus: [],
    selectSenders: [],
    selectWarehouses: [],
  },
  setFilters: () => {},
  visibleFilters: false,
  setVisibleFilters: () => {},
  // Routes
  routes: [],
  routesLoading: true,
  visibleRouteFilters: false,
  setVisibleRouteFilters: () => {},
  setRouteFilters: () => {},
  routeFilters: initialRouteFilters,
  getRoutes: () => {},
  //
  getOrders: () => {},
  deleteRoute: () => {},
  // Select Route
  selectRoutes: [],
  handleSelectRoutes: () => {},
  handleSelectRoute: () => {},
  deleteSelectRoute: () => {},
  updateNewRoute: () => {},
  addNewRoutes: () => {},
  // Select Points
  selectPoints: [],
  selectPointsOrders: [],
  selectPointsTableVisible: false,
  handleSelectPointsLoading: false,
  handleSelectPoints: () => {},
  deleteSelectPoints: () => {},
  handleSelectPointsToggleTable: () => {},
  clickPoint: null,
  handleClickPoint: () => {},
  handleClickClosePoint: () => {},
  // Map Legends
  mapLegends: MapLegendsStateInitial,
  onChangeMapLegends: () => {},
  mapLegendsWarehouses: [],
  mapLegendsBranches: [],
  // Select Orders
  selectOrders: [],
  handleSelectOrders: (orders: OrderProps[]) => {},
  handleSelectOrder: (order: OrderProps) => {},
  deleteSelectOrder: (order: OrderProps) => {},
  //
  selectMapRouteOrders: [],
  setSelectMapRouteOrders: () => {},
  routeOrdersFilters: {
    completed: false,
    waiting: false,
  },
  setRouteOrdersFilters: () => {},
});

export default function PlannerProvider({ children }: PlannerProviderProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [points, setPoints] = React.useState<PointProps[]>([]);
  const [pointsSame, setPointsSame] = React.useState<PointProps[]>([]);
  const [routes, setRoutes] = React.useState<RouteProps[]>([]);
  const [routesLoading, setRoutesLoading] = React.useState<boolean>(true);
  const [routeFilters, setRouteFilters] =
    React.useState<RouteFilters>(initialRouteFilters);
  const [orders, setOrders] = React.useState<OrderProps[]>([]);
  const [ordersCount, setOrdersCount] = React.useState<number>(0);
  const [ordersPagination, setOrdersPagination] =
    React.useState<OrdersPagination>({
      page: 0,
      pageSize: 100,
    });
  const [ordersLoading, setOrdersLoading] = React.useState<boolean>(true);
  const [filters, setFilters] = React.useState<Filters>(initialFilters);
  const [visibleFilters, setVisibleFilters] = React.useState<boolean>(false);
  const [visibleRouteFilters, setVisibleRouteFilters] =
    React.useState<boolean>(false);
  const [selectRoutes, setSelectRoutes] = React.useState<SelectRouteProps[]>(
    []
  );
  const [selectPointsTableVisible, setSelectPointsTableVisible] =
    React.useState<boolean>(false);
  const [selectPoints, setSelectPoints] = React.useState<PointProps[]>([]);
  const [handleSelectPointsLoading, setHandleSelectPointsLoading] =
    React.useState<boolean>(false);
  const [selectPointsOrders, setSelectPointsOrders] = React.useState<
    OrderProps[]
  >([]);
  const [clickPoint, setClickPoint] = React.useState<PointProps | null>(null);
  const [mapLegends, setMapLegends] = React.useState<MapLegendsStateProps>(
    MapLegendsStateInitial
  );
  const [mapLegendsWarehouses, setMapLegendsWarehouses] = React.useState([]);
  const [mapLegendsBranches, setMapLegendsBranches] = React.useState([]);
  const [selectOrders, setSelectOrders] = React.useState<OrderProps[]>([]);
  const [selectMapRouteOrders, setSelectMapRouteOrders] = React.useState<
    OrderProps[]
  >([]);
  const [routeOrdersFilters, setRouteOrdersFilters] = React.useState({
    completed: false,
    waiting: false,
  });
  const [pointSearchList, setPointSearchList] = React.useState<PointProps[]>(
    []
  );

  const context = React.useMemo(() => {
    const initialOrderFilters = {
      customers: filters.selectProjects.map((b) => b.key),
      branches: filters.selectBranches.map((b) => b.key),
      cities: filters.selectCities.map((c) => c.key),
      counties: filters.selectCounties.map((c) => c.key),
      deci_range: "",
      order_codes: [],
      order_dates: [],
      order_ids: [],
      package_codes: [],
      status: filters.selectStatus.map((c) => c.key),
      slot_ranges: filters.selectSlot.map((c) => c.key),
      end_date: filters.dates[1],
      start_date: filters.dates[0],
      senders: filters.selectSenders.map((c) => c.key),
      warehouse_codes: filters.selectWarehouses.map((c) => c.key),
    };

    return {
      refreshData: async (routeFilters: RouteFilters, newFilters: Filters) => {
        try {
          const res = await server.getPlanningPointsAll(
            newFilters || initialOrderFilters
          );
          await context.getRoutes(routeFilters);
          await context.getOrders(newFilters);
          setPoints(res);
        } catch (error) {
          console.log("ERROR refreshData", error);
        }
      },
      getData: async () => {
        try {
          // const res = await server.getPlanningPointsAll(initialOrderFilters);
          const res2 = await server.getAllWarehouse();
          const res3 = await server.getPlanningBranches();
          setMapLegendsBranches(res3);
          await context.getRoutes(routeFilters);
          // setPoints(res);
          setMapLegendsWarehouses(res2.entity.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      },
      getOrders: async (props: Filters, paginations?: OrdersPagination) => {
        const startDate = moment(props.dates[0]).isValid()
          ? convertTimezone(props.dates[0])
          : null;
        const endDate = moment(props.dates[1]).isValid()
          ? convertTimezone(props.dates[1])
          : null;

        const isDates = props.dates[0] && props.dates[1];

        setOrdersLoading(true);

        const newFilter = {
          customers: props.selectProjects.map((b) => b.key),
          branches: props.selectBranches.map((b) => b.key),
          cities: props.selectCities.map((c) => c.key),
          counties: props.selectCounties.map((c) => c.key),
          deci_range: props.selectDesiRange,
          order_codes: [],
          order_dates: [],
          order_ids: [],
          package_codes: [],
          status: props.selectStatus.map((c) => c.key),
          slot_ranges: props.selectSlot.map((c) => c.key),
          end_date: isDates ? endDate : null,
          start_date: isDates ? startDate : null,
          debit_start_date: props.dates2Select
            ? moment(props.dates2[0]).format("YYYY-MM-DD")
            : null,
          debit_end_date: props.dates2Select
            ? moment(props.dates2[1]).format("YYYY-MM-DD")
            : null,
          debit_types: props.dates2Select ? [props.dates2Select.key] : [],
          senders: props.selectSenders.map((c) => c.key),
          warehouse_codes: props.selectWarehouses.map((c) => c.key),
        };

        const newOrdersPagination = paginations || ordersPagination;

        try {
          const res = await server.getPlanningOrders(
            newFilter,
            newOrdersPagination.pageSize,
            newOrdersPagination.pageSize * newOrdersPagination.page
          );
          const res2 = await server.getPlanningPointsAll(newFilter);
          setPoints(res2);

          // Aynı pointleri olanları bir yere topla
          const groupedByCoordinates = _.groupBy(
            res2,
            (item) => `${item.latitude},${item.longitude}`
          );
          const arraySamePoints = Object.entries(groupedByCoordinates).flatMap(
            (g) => g[1]
          );
          const uniqueSamePoints = _.uniqWith(arraySamePoints, (a, b) => {
            return (
              `${a.latitude},${a.longitude}` === `${b.latitude},${b.longitude}`
            );
          });
          setPointsSame(uniqueSamePoints);
          setOrders(res[0].orders);
          setOrdersCount(res[0].total_orders_count);
          setOrdersLoading(false);
        } catch (error) {
          setOrdersLoading(false);
        }
      },
      getRoutes: async (props: RouteFilters) => {
        const startDate = moment(props.dates[0]).isValid()
          ? convertTimezone(props.dates[0])
          : null;
        const endDate = moment(props.dates[1]).isValid()
          ? convertTimezone(props.dates[1])
          : null;

        const isDates = props.dates[0] && props.dates[1];

        const sendFilter = {
          status: [],
          driver_ids: [],
          end_date: isDates ? endDate : null,
          start_date: isDates ? startDate : null,
          branches: props.selectBranches.map((b) => b.key),
          customers: props.selectProjects.map((b) => b.key),
        };

        setRoutesLoading(true);

        try {
          const res = await server.getPlanningRouteFilters(sendFilter);
          setRoutes(res[0].routes);
          setRoutesLoading(false);
        } catch (error) {
          setRoutesLoading(false);
          console.log("ERROR getRoutes", error);
        }
      },
      deleteRoute: (routeId: string) => {
        setRoutes((routes) =>
          routes.filter((route) => route.route_id !== routeId)
        );
      },
      // Select Route
      handleSelectRoutes: async (ids: string[], callback: VoidFunction) => {
        if (ids.length === 0) {
          setSelectRoutes([]);
          callback();
          return;
        }
        try {
          const res = await server.getRoutesOrdersCoords(ids);
          // orders
          // route_id
          // route_coordinates
          setSelectRoutes(
            res.map((r: any) => {
              return {
                route: r.route,
                route_color: r.route_color,
                routeCoords: r.route_coordinates
                  ? JSON.parse(r.route_coordinates)
                  : [],
                orders: r.orders,
              };
            })
          );
          callback();
        } catch (error) {
          callback();
        }
      },
      handleSelectRoute: async (
        route: RouteProps,
        color: string,
        callback: VoidFunction
      ) => {
        try {
          const res = await server.getDistributionDetailList({
            routeID: route.route_id,
          });
          const res2 = await server.getPlanlananRotalar({
            routeId: route.route_id,
          });

          const coords = res2[0]?.routeCoordinates
            ? JSON.parse(res2[0].routeCoordinates)
            : [];

          setSelectRoutes((oldRoutes) =>
            _.uniqBy(
              [
                ...oldRoutes,
                {
                  route,
                  route_color: color,
                  routeCoords: coords,
                  orders: res.entity.data,
                },
              ],
              "route.route_id"
            )
          );
          callback();
        } catch (error) {
          callback();
        }
      },
      deleteSelectRoute: (route: RouteProps) => {
        setSelectRoutes((oldSelectRoutes) =>
          oldSelectRoutes.filter((i) => i.route.route_id !== route.route_id)
        );
      },
      updateNewRoute: async (props: UpdateNewRoute) => {
        const { route, callback } = props;

        setRoutes((oldRoutes) => {
          const newArray = [...oldRoutes];
          const index = newArray.findIndex(
            (r) => r.route_id === route.route_id
          );

          if (index > -1) {
            newArray[index] = route;
            callback();
            return newArray;
          }

          callback();
          return oldRoutes;
        });
      },
      addNewRoutes: (routes: RouteProps[]) => {
        setRoutes((oldRoutes) => [...routes, ...oldRoutes]);
      },
      // Select Points
      handleSelectPoints: async (points: PointProps[]) => {
        if (points.length === 0) {
          context.deleteSelectPoints();
          return;
        }

        setHandleSelectPointsLoading(true);

        const pointsIds = points.map((n) => n.order_id);

        try {
          const res = await server.getPlanningGetOrdersIDS(pointsIds);
          setSelectPoints(points);
          setSelectPointsOrders(res[0].orders);
          setHandleSelectPointsLoading(false);
        } catch (error) {
          setHandleSelectPointsLoading(false);
        }
      },
      deleteSelectPoints: () => {
        setSelectPoints([]);
        setSelectPointsOrders([]);
      },
      handleSelectPointsToggleTable: () => {
        setSelectPointsTableVisible((visible) => !visible);
      },
      handleClickPoint: (point: PointProps) => {
        setClickPoint(point);
      },
      handleClickClosePoint: () => {
        setClickPoint(null);
      },
      // Map Legends
      onChangeMapLegends: (props: MapLegendsStateProps) => {
        setMapLegends(props);
      },
      // Select Orders
      handleSelectOrders: (orders: OrderProps[]) => {
        setSelectOrders(orders);
      },
      handleSelectOrder: (order: OrderProps) => {
        setSelectOrders((orders) => [...orders, order]);
      },
      deleteSelectOrder: (order: OrderProps) => {
        setSelectOrders((orders) => orders.filter((o) => o.id !== order.id));
      },
    };
  }, [filters, routeFilters, ordersPagination]);

  React.useEffect(() => {
    context.getData();
  }, []);

  React.useEffect(() => {
    if (selectPoints.length === 0 && selectPointsTableVisible) {
      setSelectPointsTableVisible(false);
    }
  }, [selectPoints, selectPointsTableVisible]);

  const newPoints = React.useMemo(() => {
    return pointSearchList.length > 0 ? pointSearchList : points;
  }, [pointSearchList, points]);

  const onSetSelectMapRouteOrders = React.useCallback(
    (selectOrders: OrderProps[]) => {
      const discardsCompletedOrders = selectOrders.filter((o) => {
        return !["DELIVERED", "NOT_DELIVERED"].includes(o.delivery_status);
      });

      const isDifferent =
        selectOrders.length !== discardsCompletedOrders.length;

      if (isDifferent) {
        enqueueSnackbar(
          "Tamamlanmış siparişler ile ilgili işlem yapamazsınız.",
          {
            variant: "info",
            autoHideDuration: 5000,
          }
        );
      }

      setSelectMapRouteOrders(discardsCompletedOrders);
    },
    []
  );

  if (loading) {
    return (
      <Stack height="100vh" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  const value = {
    refreshData: context.refreshData,
    pointsSame,
    points: newPoints,
    setPointSearchList,
    orders,
    // Orders Pagination
    ordersCount,
    ordersPagination,
    setOrdersPagination,
    // Order Filters
    ordersLoading,
    filters,
    setFilters,
    visibleFilters,
    setVisibleFilters,
    // Route
    routes: _.uniqBy(routes, "route_id"),
    routesLoading,
    routeFilters,
    setRouteFilters,
    getRoutes: context.getRoutes,
    visibleRouteFilters,
    setVisibleRouteFilters,
    //
    getOrders: context.getOrders,
    deleteRoute: context.deleteRoute,
    // Select Route
    selectRoutes,
    handleSelectRoutes: context.handleSelectRoutes,
    handleSelectRoute: context.handleSelectRoute,
    deleteSelectRoute: context.deleteSelectRoute,
    updateNewRoute: context.updateNewRoute,
    addNewRoutes: context.addNewRoutes,
    // Select Points
    selectPoints,
    handleSelectPointsLoading,
    handleSelectPoints: context.handleSelectPoints,
    deleteSelectPoints: context.deleteSelectPoints,
    handleSelectPointsToggleTable: context.handleSelectPointsToggleTable,
    selectPointsOrders,
    selectPointsTableVisible,
    clickPoint,
    handleClickPoint: context.handleClickPoint,
    handleClickClosePoint: context.handleClickClosePoint,
    // Map Legends
    mapLegends,
    onChangeMapLegends: context.onChangeMapLegends,
    mapLegendsWarehouses,
    mapLegendsBranches,
    // Select Orders
    selectOrders,
    handleSelectOrders: context.handleSelectOrders,
    handleSelectOrder: context.handleSelectOrder,
    deleteSelectOrder: context.deleteSelectOrder,
    //
    selectMapRouteOrders,
    setSelectMapRouteOrders: onSetSelectMapRouteOrders,
    routeOrdersFilters,
    setRouteOrdersFilters,
  };

  return (
    <PlannerContext.Provider value={value}>{children}</PlannerContext.Provider>
  );
}

export const usePlanner = () => React.useContext(PlannerContext);

import * as React from "react";
import { Link } from "react-router-dom";
import { Stack, Box } from "@mui/material";

export interface DispatcherNewHeaderProps {}

export default function DispatcherNewHeader(props: DispatcherNewHeaderProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ minHeight: 64, maxHeight: 64, px: 2, gap: 2 }}
    >
      <Link
        to="/"
        style={{
          width: 140,
          height: 60,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/hey-bringo-logo.png"
          sx={{
            display: "block",
            width: "100%",
            height: "auto",
            objectFit: "contain",
          }}
        />
      </Link>
    </Stack>
  );
}

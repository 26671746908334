export const TextFirstLetter = (text = "") => {
  if (!text) return "";
  const str = text.toLocaleLowerCase("en-US");
  //split the above string into an array of strings
  //whenever a blank space is encountered

  const arr = str.split(" ");

  //loop through each element of the array and capitalize the first letter.

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  const str2 = arr.join(" ");

  return str2;
};

export function underAgeValidate(birthday) {
  // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
  var optimizedBirthday = birthday.replace(/-/g, "/");

  //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
  var myBirthday = new Date(optimizedBirthday);

  // set current day on 01:00:00 hours GMT+0100 (CET)
  var currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00";

  // calculate age comparing current date and borthday
  var myAge = ~~((Date.now(currentDate) - myBirthday) / 31557600000);

  if (myAge < 18) {
    return false;
  } else {
    return true;
  }
}

export function removeTurkishChars(text) {
  const turkishChars = {
    ı: "i",
    ş: "s",
    ğ: "g",
    ü: "u",
    ö: "o",
    ç: "c",
    İ: "I",
    I: "ı",
    Ş: "s",
    Ğ: "g",
    Ü: "u",
    Ö: "o",
    Ç: "c",
  };
  return text.replace(/[^A-Za-z0-9]/g, (match) => turkishChars[match] || match);
}

export function searchByName(search, list) {
  // Büyük/küçük harf duyarlılığını kaldırmak için isimleri küçük harfe çeviriyoruz
  const lowercaseSearch = removeTurkishChars(search.toLowerCase());

  // Filtreleme işlemi
  const filteredList = list.filter(function (item) {
    const value = item?.name || item?.value || "";

    const lowercaseName = removeTurkishChars(value.toLowerCase());
    return lowercaseName.indexOf(lowercaseSearch) !== -1;
  });

  return filteredList;
}

export function isValidCoordinates(latitude, longitude) {
  if (typeof latitude !== "number" || typeof longitude !== "number") {
    return false;
  }

  if (latitude < -90 || latitude > 90 || longitude < -180 || longitude > 180) {
    return false;
  }

  return true;
}

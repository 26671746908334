import React from "react";
import { DndProvider } from "react-dnd";
import { Box, CircularProgress, Stack } from "@mui/material";
import { HTML5Backend } from "react-dnd-html5-backend";
//
import DispatcherNewMap from "./Map/index";
import DispatcherNewHeader from "./Header";
import DispatcherNewOrders from "./Orders";
import DispatcherNewRoutes from "./Routes";
import DispatcherNewFilters from "./Filters/OrderFiltersE";
import DispatcherNewRouteFilters from "./Filters/RouteFilters";
import { usePlanner } from "contexts/PlannerProvider";

export type Cursors =
  | "centered"
  | "freeHand"
  | "lasso"
  | "allSelect"
  | "measure"
  | "ordersCompleted"
  | "ordersNotCompleted";
export interface Props {
  props?: any;
}

export default function DispatcherPlannerNewScreen(props: Props) {
  const { selectPointsTableVisible, ordersLoading } = usePlanner();
  const [fullScreen, setFullScreen] = React.useState<boolean>(false);
  const [cursor, setCursor] = React.useState<Cursors>("centered");

  const onChangeCursor = React.useCallback((newCursor: Cursors) => {
    setCursor(newCursor);
  }, []);

  const hiddenRows = ["freeHand", "lasso"].includes(cursor);
  const hidden = selectPointsTableVisible || hiddenRows;

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack sx={{ height: "100vh", overflow: "hidden", position: "relative" }}>
        <DispatcherNewHeader />
        <Stack direction="row" flex={1}>
          <Box sx={{ flex: 1, overflow: "hidden", position: "relative" }}>
            <DispatcherNewMap cursor={cursor} setCursor={onChangeCursor} />
            {ordersLoading && (
              <Stack
                spacing={2}
                sx={{
                  inset: 0,
                  zIndex: 9,
                  position: "absolute",
                  bgcolor: "grey.50056",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={30} color="info" />
              </Stack>
            )}
            {/* <DispatcherNewMap /> */}
          </Box>
          <Box
            sx={{
              zIndex: 2,
              position: "relative",
              transition: ".2s ease",
              width: hidden ? 0 : 670,
              bgcolor: "background.default",
            }}
          >
            <DispatcherNewRoutes />
          </Box>
        </Stack>
        <Box
          sx={{
            transition: ".2s ease",
            minHeight: hidden ? 0 : 378,
          }}
        />
        <Box
          sx={{
            left: 0,
            width: 1,
            right: 0,
            bottom: 0,
            overflow: "hidden",
            position: "absolute",
            transition: ".2s ease",
            bgcolor: "background.default",
            maxHeight: fullScreen ? "calc(100vh - 64px)" : 378,
            height: hidden ? 0 : 1,
            zIndex: hidden ? -3 : 3,
            opacity: hidden ? 0 : 1,
          }}
        >
          <DispatcherNewOrders
            fullScreen={fullScreen}
            toggleFullScreen={() => setFullScreen(!fullScreen)}
          />
        </Box>
        <DispatcherNewFilters />
        <DispatcherNewRouteFilters />
      </Stack>
    </DndProvider>
  );
}
